import {reactive} from 'vue';

// zentraler Zustand
const state = reactive( {
    isLessonComplete: false,
    courseInfo: {
        count: 0,
        complete: 0,
    }
} );

// Methode, um den Wert zu ändern
function completeLesson(){
    console.log("Composition: true")
    state.isLessonComplete = true;
}

function resetLesson(){
    console.log("Composition: false")
    state.isLessonComplete = false;
}

function addLessionComplete(){
    state.courseInfo.complete++;
}
function removeLessionComplete(){
    state.courseInfo.complete--;
}
function addCount(){
    state.courseInfo.count++;
}

export {
    state,
    completeLesson,
    resetLesson,
    addLessionComplete,
    removeLessionComplete,
    addCount
};
