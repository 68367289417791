<template>

    <div v-if="!isDsgvoReady"
         class="text-center bg-slate-800 bg-opacity-75 transition-opacity min-h-full flex flex-col place-content-center">
        <div class="mb-2">${ dsgvoText }</div>
        <div>
            <button
                class="text-white border border-white hover:bg-white hover:text-slate-800 rounded-sm px-3 py-2"
                @click="acceptDsgvo()"
            >${ dsgvoButton }
            </button>
        </div>
    </div>
    <div v-else id="yt-player" class="w-full aspect-video border-sm">
    </div>

</template>

<script>
import { computed } from "vue";
import { state, completeLesson } from "../composition/LessonCompleteComposition";

export default {
    name: "YoutubeVideo",
    props: {
        userId: String,
        lessonId: String,
        startVideoSeconds: {
            type: String,
            default: 0
        },
        dsgvoReady: {
            type: Boolean,
            default: false
        },
        dsgvoText: {
            type: String,
            default: 'Möchten Sie den Youtube-Player laden?'
        },
        dsgvoButton: {
            type: String,
            default: 'Ja bitte laden'
        },
        videoId: String
    },
    data(){
        return {
            isDsgvoReady: false,
            playTimer: null,
            videoLink: 'https://www.youtube-nocookie.com/embed/' + this.videoId + '?autoplay=1'
        }
    },
    methods: {
        acceptDsgvo(){
            this.isDsgvoReady = true;
            localStorage.setItem('videoGdprAccepted', 'yes');
            this.loadYoutubeVideo();
        },
        loadYoutubeVideo(){
            const tag = document.createElement( 'script' );
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName( 'script' )[ 0 ];
            firstScriptTag.parentNode.insertBefore( tag, firstScriptTag );
        },
        onPlayerStateChange( event ){
            if( event.data === YT.PlayerState.PLAYING ){
                const duration = this.player.getDuration();
                const currentTime = this.player.getCurrentTime();

                let that = this;
                this.playTimer = setInterval( function(){
                    const time = that.player.getCurrentTime();
                    const total = that.player.getDuration();

                    // send a request all 10 seconds to start the video with this timestamp
                    if( parseInt( time % 10 ) === 0 ){
                        that.updateViewTime();
                    }

                    if( total - time <= 20 ){
                        console.log( "video is about to end, in time" );
                        clearInterval( that.playTimer );
                        that.completeLesson();
                    }

                }, 1000 )

            }else if( event.data === YT.PlayerState.PAUSED ){
                clearInterval( this.playTimer );

            }else if( event.data === YT.PlayerState.ENDED ){
                clearInterval( this.playTimer );
                console.log( "video is about to end, ended" );
                this.completeLesson();
            }
        },
        updateViewTime(){
            if( this.userId != null ){
                let xhrTimestamp = new XMLHttpRequest();
                xhrTimestamp.open( 'GET', '/update-lesson-watching/' + this.userId + '/' + this.lessonId + '/' + this.player.getCurrentTime() );
                xhrTimestamp.send();
            }
        }
    },
    mounted(){
        if (localStorage.getItem('videoGdprAccepted') == 'yes') {
            this.isDsgvoReady = true;
            this.loadYoutubeVideo();
        } else {
            this.isDsgvoReady = this.dsgvoReady;
        }
        window.onYouTubeIframeAPIReady = () => {
            this.player = new YT.Player( 'yt-player', {
                height: '100%',
                width: '100%',
                videoId: this.videoId,
                playerVars: {
                    autoplay: 1,
                    controls: 1,
                    rel: 0,
                    showinfo: 0,
                    modestbranding: 1,
                    playsinline: 1,
                    start: this.startVideoSeconds,
                    origin: window.location.origin
                },
                events: {
                    'onStateChange': this.onPlayerStateChange
                }
            } );
        }
    },
    setup() {
        // Berechnete Eigenschaft, die die zentrale Variable liest und beobachtet
        const isLessonComplete = computed(() => state.isLessonComplete);
        return {
            isLessonComplete,
            completeLesson
        };
    }
}
</script>