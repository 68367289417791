<template>

</template>
<script>
export default {
    name: 'CourseProgress',
    props: {
        courseId: {
            type: Number,
            default: 0
        },
        lessonId: {
            type: Number,
            default: 0
        },
        timeoutSeconds: {
            type: Number,
            default: 0
        },
        updateUrl: {
            type: String
        }
    },

    /*computed: {
        detailedCourseCompleted(){
            return this.$store.state.detailedCourseCompleted;
        }
    },*/

    data() {
        return {
            timer: null
        }
    },
    mounted: function () {
        // Update Timer
        this.timer = setTimeout( this.updateCourse, this.timeoutSeconds*1000 );

        if (!this.$store.state.course.complete) {
            console.log('not complete');
        }else{
            console.log('complete');
        }
    },
    methods: {
        updateCourse: function () {

            const formData = new FormData();
            formData.append( 'courseId', this.courseId );
            formData.append( 'lessonId', this.lessonId );

            this.sendUpdateXhr( formData );

        },

        sendUpdateXhr: function( formData ){
            if( typeof formData == "undefined" ){
                return '';
            }
            const _xhr = new XMLHttpRequest();
            _xhr.open('POST', this.updateUrl );
            _xhr.onload = function(){
                if( _xhr.readyState === XMLHttpRequest.DONE ){
                    const response = JSON.parse( _xhr.responseText );
                    console.log('Ready:', response);
                }
            }.bind(this);

            _xhr.send( formData );
        }
    }
    /*,
    watch: {
        detailedCourseCompleted(){
            this.updateCourse();
        }
    }*/
}
</script>

<style>
</style>
