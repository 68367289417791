<template>
    <div id="lesson-complete">
        ${ label } <input type="checkbox" name="lessonComplete" :disabled="this.readOnly == true ? true : false" v-on:change="changeCheckbox()" v-model="lessonComplete">
    </div>
</template>

<script>
import {computed, watchEffect} from "vue";
import {
    addCount,
    addLessionComplete,
    completeLesson,
    removeLessionComplete,
    resetLesson,
    state
} from "../composition/LessonCompleteComposition";

export default {
    name: 'LessonComplete',
    props: {
        label: String,
        lessonValue: {
            type: Boolean,
            default: false
        },
        lessonId: {
            type: Number,
            default: 0
        },
        updateUrl: {
            type: String
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        isDetail: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            canWatch: false,
            lessonComplete: false,
        }
    },
    beforeMount: function () {
        if (this.lessonValue) {
            this.lessonComplete = this.lessonValue.toString();
        }
        this.canWatch = true;
    },
    mounted: function () {
        if (this.label === '') {
            this.label = 'Lesson Complete';
        }

        // Add Store Counts
        //this.$store.state.course.count++;
        addCount()
        if (this.lessonComplete === 'true' || this.lessonComplete === true) {
            //this.$store.state.course.complete++;
            addLessionComplete()
            this.setDetailLessonComplete(true);
        }

    },
    methods: {
        setDetailLessonComplete: function (isComplete) {
            if (this.isDetail) {
                if( isComplete ){
                    completeLesson()
                }else{
                    resetLesson()
                }
            }
        },
        setComplete: function (newValue, oldValue, updateUrl = '') {
            if (newValue.toString() === oldValue.toString()) {
                return;
            }
            let markCompleteUrl = this.updateUrl;
            if (updateUrl !== '') {
                markCompleteUrl = updateUrl;
            }
            const formData = new FormData();
            formData.append('newValue', newValue);
            // xhr new Value to server
            const xhr = new XMLHttpRequest();
            xhr.open('POST', markCompleteUrl);

            xhr.onload = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        if (response.complete === "true" || response.complete === true) {
                            //this.$store.state.course.complete++;
                            addLessionComplete()
                            this.setDetailLessonComplete(true)
                        } else {
                            //this.$store.state.course.complete--;
                            removeLessionComplete()
                            this.setDetailLessonComplete(false)
                        }
                        this.lessonComplete = response.complete.toString();
                    }
                }
            }.bind(this);

            xhr.send(formData);
        },
        changeCheckbox: function () {
            this.setComplete(this.lessonComplete, !this.lessonComplete);
        }
    },
    setup() {
        // Berechnete Eigenschaft, die die zentrale Variable liest und beobachtet
        let isLessonComplete = computed(() => state.isLessonComplete);
        watchEffect(() => {
            if (state.isLessonComplete) {
                isLessonComplete = true;
                console.log( 'Changed lesson to Complete' );
            }
        });

        return {
            isLessonComplete
        };
    }
}
</script>

<style>
</style>
