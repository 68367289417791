"use strict";

window.addEventListener('DOMContentLoaded', (event) => {
    // Burger Icon
    const burgerNavigation = document.getElementById('burger-navigation')

    // Burger navigation Background
    const burgerNavBg = document.getElementById('burger-navigation-bg')

    // Burger navigation Nav
    const burgerNavigationNav = document.getElementById('burger-navigation-navigation')

    // On Click Icon
    burgerNavigation.addEventListener('click', function (evt) {

        // show background
        burgerNavBg.classList.toggle('hidden')

        // show nav
        burgerNavigationNav.classList.toggle('hidden')

    }, false);

    // On Click Background
    burgerNavBg.addEventListener('click', (event) => {
        // hide background
        burgerNavBg.classList.toggle('hidden')

        // hide nav
        burgerNavigationNav.classList.toggle('hidden')
    })
})
