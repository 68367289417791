<script>
import CourseCompleteCounterVue from './CourseCompleteCounter.vue'
import CourseProgressVue from './CourseProgress.vue'
import LessonCompleteVue from './LessonComplete.vue'
import YoutubeVideo from "./YoutubeVideo.vue";

export default {
    name: 'PimcoreCastsApp',
    components: {
        'lesson-complete': LessonCompleteVue,
        'course-complete': CourseCompleteCounterVue,
        'course-progress': CourseProgressVue,
        'youtube-video': YoutubeVideo,
        'course-complete-counter': CourseCompleteCounterVue,
    },
    delimiters: ['${', '}']
}
</script>
