/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss'

// Is Clickable
import './js/clickableContainer'
import './js/burgerNavigation'
import './js/hashcash'


// Vue
import {createApp} from 'vue'
import App from './vue/App.vue'

const pimcoreCastsApp = createApp(App)
pimcoreCastsApp.mount('#PimcoreCastsApp')
