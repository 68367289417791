"use strict";

document.querySelector('body').addEventListener('click', function (evt) {
    let target = evt.target;
    let goToLink = target.closest('.clickable-container');
    if (goToLink && !target.closest('a, button, input, select, textarea, label')) {
        let link = goToLink.querySelector('.clickable-container-link');
        if (!link) {
            link = goToLink.querySelector('a');
            console.log(link);
        }
        let href = link.getAttribute('href');

        if (window.getSelection().toString()) {
            evt.preventDefault();
            evt.stopImmediatePropagation();
        } else if (href) {
            if (link) {
                try {
                    link.dispatchEvent(new MouseEvent(evt.originalEvent.type, evt.originalEvent));

                    // allow crtl + click on the whole .js-go-to-link element.
                    // this works only for some browsers (eg: Chrome, Edge)
                    // but does not break anything for others (eg: IE, Firefox)
                } catch (e) {
                    link.click();
                }
            }

            evt.preventDefault();
            evt.stopImmediatePropagation();
        }
    }
}, false);