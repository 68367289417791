<template>
    <div class="flex flex-col p-2">
        <span>${ headline }:</span>
        <div class="items-center w-[80px] h-[80px] align-center relative">
            <div class="absolute inset-0 flex justify-center items-center z-10" v-bind:data-percent="statCounter" data-scale-color="#e3e3e3">
                <span class="text-sm">${ statCounter }</span>%
            </div>
            <Doughnut ref="chartRef" :data="chartData" :options="options"/>
        </div>
    </div>
</template>

<script setup>
import {state} from "../composition/LessonCompleteComposition";
import {computed, ref, watchEffect} from "vue";
import {ArcElement, Chart as ChartJS} from 'chart.js'
import {Doughnut} from "vue-chartjs";

const props = defineProps({
    headline: {
        type: String,
        default: 'Course Complete'
    }
})

const chartRef = ref( null )

// Test

ChartJS.register( ArcElement )
const courseCount = ref( 0 )
const courseComplete = ref( 0 )
const statCounter = ref( 0 )
const chartContent = ref([0, 100]);

let chartData = computed(() => ({
    labels: [''],
    datasets: [
        {
            backgroundColor: [ '#336699', '#efefef' ],
            data: chartContent.value
        }
    ]
}))

const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    showTooltip: false,
    updateMode: 'active'
}

const updateChart = () => {
    chartContent.value = [Math.round(statCounter.value), Math.round( 100-statCounter.value ) ]
}

watchEffect(( onMounted ) => {
    courseCount.value = state.courseInfo.count
    courseComplete.value = state.courseInfo.complete

    if( courseCount.value === 0 ){
        statCounter.value = 0
    }else{
        statCounter.value = Math.round((courseComplete.value / courseCount.value) * 100 )
    }
    updateChart()
} )

</script>

<style>
</style>